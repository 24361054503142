import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Header = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1 className="animated" data-aos="fade-up">
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                {/**
                 * <a
                  href="https://ganaderia.sinarca.co/"
              className="btn-custom btn-spacing"
                  data-aos="fade-up"
                >
                  Ingreso Clientes
                </a>{" "}
                <br></br> 
                 */}
                <p className="animated" data-aos="fade-up">{props.data ? props.data.paragraph : "Loading"}</p>
               
   {/**
    *        <a
                  href="https://ganaderia.sinarca.co/authentication/sign-up"
                  //className="btn btn-lg  btn-register btn-register-color"
                  className="btn-custom btn-register-color btn-spacing"
                  
                  
                  data-aos="fade-up"
                >
                  ¡Regístrate Gratis!  
                </a>{" "}
    * 
    * 
    */}             
         
       
                 
                

              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};