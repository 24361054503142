import * as React from 'react';
import { Grid } from '@mui/material';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import { useEffect } from 'react';
import PlanBasico from "../data/PlanBasico.jpg";
import PlanEstandar from "../data/PlanEstandar.jpg";
import PlanPremium from "../data/PlanPremium.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css';

const plans = [
  {
    title: "Plan Básico",
    description: "¡Empieza tu viaje con nuestro Plan Básico! Disfruta de características increíbles a un precio asequible. Ideal para principiantes.",
    image: PlanBasico,
    link: "https://wa.me/573232825739/?text=Hola%20Equipo%20de%20Ruta%20Ganadera,%20estoy%20interesado%20en%20tomar%20el%20Plan%20B%C3%A1sico%20👋",
    buttonColor: "linear-gradient(45deg, #008000 30%, #006400 90%)",
    buttonText: "Disfruta de un Mes Gratis"
  },
  {
    title: "Plan Estándar",
    description: "¡Eleva tu experiencia con nuestro Plan Estándar! Accede a más funciones y disfruta de mayor flexibilidad. Perfecto para usuarios intermedios.",
    image: PlanEstandar,
    link: "https://wa.me/573232825739/?text=Hola%20Equipo%20de%20Ruta%20Ganadera,%20estoy%20interesado%20en%20tomar%20el%20Plan%20Est%C3%A1ndar%20👋",
    buttonColor: "linear-gradient(45deg, #e50914 30%, #b20710 90%)",
    buttonText: "Disfruta Estándar 1 Mes Gratis"
  },
  {
    title: "Plan Premium",
    description: "¡Lleva todo al siguiente nivel con nuestro Plan Premium! Disfruta de todas nuestras características avanzadas y obtén el máximo valor. Ideal para usuarios avanzados.",
    image: PlanPremium,
    link: "https://wa.me/573232825739/?text=Hola%20Equipo%20de%20Ruta%20Ganadera,%20estoy%20interesado%20en%20tomar%20el%20Plan%20Premium%20👋",
    buttonColor: "linear-gradient(45deg, #FF5733 30%, #C70039 90%)",
    buttonText: "Disfruta Premium 1 Mes Gratis"
  }
];

export default function Team() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Grid container spacing={1} sx={{ cursor: 'help' }}>
      {plans.map((plan, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} data-aos="fade-up" data-aos-duration="1000">
          <Card sx={{ width: 340, position: 'relative' }}>
            <div>
              <Typography level="title-lg" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                {plan.title}
              </Typography>
              <Typography level="body-sm" sx={{ fontSize: '1.2rem' }}>
                {plan.description}
              </Typography>
              <IconButton
                aria-label={`bookmark ${plan.title}`}
                variant="plain"
                color="neutral"
                size="sm"
                sx={{ position: 'absolute', top: '0.975rem', right: '0.10rem' }}
              >
                
              </IconButton>
            </div>
            <AspectRatio minHeight="120px" maxHeight="200px">
              <img
                src={plan.image}
                loading="lazy"
                alt={plan.title}
              />
            </AspectRatio>
            <CardContent orientation="horizontal">
              <Button
                variant="solid"
                size="md"
                color="primary"
                aria-label={`Explore ${plan.title}`}
                href={plan.link}
                target="_blank"
                sx={{ 
                  ml: 'auto', 
                  alignSelf: 'center', 
                  fontWeight: 100, 
                  background: plan.buttonColor,
                  fontSize: '1.5rem', // Aumenta el tamaño de la fuente
                  padding: '1rem 2rem' // Aumenta el padding
                }}
              >
                {plan.buttonText}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}