import * as React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const plans = [
  {
    title: "Andi del Futuro",
    image:
      "https://i0.wp.com/andidelfuturo.com/andi/wp-content/uploads/2022/10/andi-del-futuro.png?w=1226&ssl=1",
  },
  {
    title: "ANDI Mas País",
    image: "https://www.andi.com.co/Uploads/logo_637164318144189854.png",
  },
  {
    title: "Fundación ANDI",
    image:
      "https://www.andi.com.co/Uploads/0.%20Logo%20Fundacioi%CC%80n%20ANDI%20-%20Borde%20Blanco.png",
  },
  {
    title: "Fundación ANDI",
    image: "https://www.distritojoven.gov.co/images/distrito_joven_web.svg",
  },
  // Agrega más planes según sea necesario
];

export default function NuestrosAsesores() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
    <div className="text-center">
    <div className="container">
      <div
        className="section-title"
        style={{ marginBottom: "20px" }}
        data-aos="zoom-in"
      >
        <h2>Nuestros asesores y promotores</h2>
      </div>
      </div>
      </div>
      <section
        style={{
          display: "flex",
          width: "100%",
          height: "20%",
          maxWidth: "100%",
          maxHeight: "100%",
          placeItems: "center",
          margin: "0px",
          padding: "0px",
          listStyleType: "none",
          opacity: 1,
          maskImage:
            "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%)",
          overflow: "hidden",
          backgroundColor: "rgb(33, 136, 56)", // Color de fondo cambiado
        }}
      >
        <ul
          style={{
            display: "flex",
            width: "80%",
            height: "20%",
            maxWidth: "100%",
            maxHeight: "100%",
            placeItems: "center",
            margin: "0",
            padding: "0",
            listStyleType: "none",
            textIndent: "none",
            gap: "5px", // Reduce the gap between images
            position: "relative",
            flexDirection: "row",
            willChange: "transform",
            animation: "scroll 20s linear infinite",
          }}
        >
          {plans.map((plan, index) => (
            <li key={index} style={{ flexShrink: 0 }}>
              <div
                style={{
                  position: "relative",
                  borderRadius: "inherit",
                  width: "200px",
                  height: "120px",
                }}
              >
                {" "}
                {/* Ajusta el tamaño del contenedor de la imagen */}
                <img
                  decoding="async"
                  src={plan.image}
                  alt={plan.title}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "inherit",
                    objectPosition: "center",
                    objectFit: "contain", // Ajusta la imagen dentro del contenedor
                  }}
                />
              </div>
            </li>
          ))}
          {plans.map((plan, index) => (
            <li key={index + plans.length} style={{ flexShrink: 0 }}>
              <div
                style={{
                  position: "relative",
                  borderRadius: "inherit",
                  width: "200px",
                  height: "120px",
                }}
              >
                {" "}
                {/* Ajusta el tamaño del contenedor de la imagen */}
                <img
                  decoding="async"
                  src={plan.image}
                  alt={plan.title}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "inherit",
                    objectPosition: "center",
                    objectFit: "contain", // Ajusta la imagen dentro del contenedor
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
        <style jsx>{`
          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
        `}</style>
      </section>
    </>
  );
}
